<template>
  <section>
    <section class="top">
      <div>
        <p class="att_title">
          Attendance
        </p>
        <p class="att_subtitle">
          Select A Class
        </p>
      </div>
    </section>

    <section class="content">
      <div
        v-if="attStat === 'success'"
        class="grid"
      >
        <VCard
          v-for="stdClass in classes"
          :key="stdClass"
          class="shadow"
          ripple
          @click="selectOption(stdClass)"
        >
          <div>
            {{ stdClass }}
            <p>CLASS</p>
          </div>
        </VCard>
      </div>
    </section>
    <VDialog
      v-model="dialog"
      width="350"
      :no-click-animation="true"
      :lazy="true"
      persistent
    >
      <div
        id="dialog"
        class="elevation-20"
      >
        <VToolbar
          flat
          dense
          dark
          color="primary"
          style="border-radius: 8px 8px 0 0"
        >
          <span class="att_main_txt">Options</span>
          <VSpacer />
          <VBtn
            icon
            @click="dialog = false"
          >
            <VIcon>close</VIcon>
          </VBtn>
        </VToolbar>
        <div class="dialogDiv">
          <div>
            <VBtn
              color="primary"
              round
              depressed
              block
              large
              @click="selection('new')"
            >
              <VIcon left>
                add
              </VIcon>
              Add Attendance
            </VBtn>
            <VBtn
              color="primary"
              round
              depressed
              block
              large
              class="mt-3"
              @click="selection('view')"
            >
              <VIcon left>
                visibility
              </VIcon>
              View Attendance
            </VBtn>
          </div>
        </div>
      </div>
    </VDialog>
    <div v-if="attStat === 'failed'">
      <div id="noData">
        An Error Occurred, Please Try Again Later
      </div>
    </div>
    <div v-if="attStat === 'loading'">
      <div id="noData">
        <div>
          <div class="mb-4">
            <VProgressCircular
              indeterminate
              color="primary"
              :width="3"
              :size="40"
            />
          </div>
          Loading Data
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import store from '../../store';
import api from '../../conf/api';

export default {
  data: () => ({
    attStat: '',
    dialog: false,
    classes: [],
    selectionsa: '',
  }),
  beforeMount() {
    this.attStat = 'loading';
    axios.get(`${api}/settings/classes`).then((res) => {
      if (res.data.data.length !== 0) {
        this.classes = res.data.data;
        this.attStat = 'success';
      } else {
        this.attStat = 'failed';
      }
    });
  },
  methods: {
    selectOption(val) {
      this.dialog = true;
      store.commit('setSelectedClass', val);
    },
    selection(val) {
      if (val === 'view') {
        this.$router.push(`${this.$route.path}/${this.$store.state.selectedClass}`);
        store.commit('setSelectedClass', null);
      }
      if (val === 'new') {
        this.$router.push(`${this.$route.path}/new?class=${this.$store.state.selectedClass}`);
        store.commit('setSelectedClass', null);
      }
    },

  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Attendance/index';
</style>
